<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5>{{ $t('filters') }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" lg="2" class="d-flex align-items-center justify-content-start">
          <label class="text-capitalize">{{ $t("table.show") }}</label>
          <v-select
            :options="perPageOptions"
            :value="perPage"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="(val) => $emit('update:perPage', val)"
          />
          <label class="text-capitalize">{{ $t("table.entries") }}</label>
        </b-col>
        <b-col cols="12" lg="10">
          <b-row align-v="center" class="d-flex align-items-center">
            <!-- Per Page -->
            <b-col cols="6" class="mb-1">
              <label class="text-capitalize mr-2">
                {{ $t("action_plan.list.filters.tenants") }}
              </label>
              <v-select
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
                multiple
                class="w-100"
                @input="(val) => $emit('update:tenantFilter', val)"
              />
            </b-col>

            <!-- Search -->
            <b-col cols="6">
              <div class="mb-1">
                <label class="text-capitalize mr-2"></label>
                <b-form-input
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search')"
                  @input="(val) => $emit('update:searchQuery', val)"
                />
              </div>
            </b-col>

            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-row
                align-v="center"
                class="d-flex align-items-center"
                v-if="$can('filter_datetime', 'Alarm')"
              >
                <b-col cols="6" md="6" class="mb-md-0 mb-2">
                  <b-form-group>
                    <label class="text-capitalize">
                      {{ $t("action_plan.list.filters.start_date") }}
                      </label>
                    <flat-pickr
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        time_24hr: true,
                      }"
                      :value="startDatetime"
                      @on-change="
                        (selectedDates, val) => $emit('update:startDatetime', val)
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="6" class="mb-md-0 mb-2">
                  <b-form-group>
                    <label class="text-capitalize">
                      {{ $t("action_plan.list.filters.end_date") }}
                    </label>
                    <flat-pickr
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        time_24hr: true,
                      }"
                      :value="endDatetime"
                      @on-change="
                        (selectedDates, val) => $emit('update:endDatetime', val)
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
    BBadge,
    flatPickr,
  },
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    perPageOptions: {
      type: Array,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    searchQuery: {
      type: String,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div class="info-slot-container">
    <div class="p-2" v-if="actionPlans.length && !actionPlanSelectedId">
      <p class="lead text-center font-medium-3 mb-2">{{ $t("action_plan.title") }}</p>
      <div class="action-plan-list d-flex flex-wrap">
        <div
          class="action-plan-item d-flex align-items-center justify-content-center p-1 mr-1 mb-1"
          v-for="(actionPlan, index) in actionPlans"
          :key="index"
          @click="onActionPlanSelected(actionPlan)"
        >
          <span class="lead">{{ actionPlan.name }}</span>
        </div>
      </div>
    </div>
    <div class="p-1" v-if="actionPlanSelectedId">
      <action-plan-viewer
        v-model="actionPlanHistory"
        :action-plan-id="actionPlanSelectedId"
        :parent-container="$el"
        :config="config"
      />
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import ActionPlanViewer from "@/components/ActionPlanViewer";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import { v4 as uuidv4 } from "uuid";
import LayoutApi from "@/libs/LayoutApi";

const layoutApi = new LayoutApi(store);

export default {
  props: {
    data: Object,
  },
  components: {
    ActionPlanViewer,
  },
  data() {
    return {
      actionPlans: [],
      actionPlanSelectedId: null,
      actionPlanHistory: null,
      alarmData: null,
      slotData: null,
      config: {
        userId: null,
        alarmId: null,
        uuid: null,
      },
    };
  },
  created() {
    this.slotInfo = this.data;
    this.slotData = this.slotInfo.data;
  },
  async mounted() {
    if (
      [SOURCES.MAIN_SEARCH, SOURCES.COMMAND].includes(this.slotInfo.data.in_app_source)
    ) {
      const { data: data } = await this.fetchActionPlans();

      if (data.data) {
        const isEmpty = Array.isArray(data.data.data) && data.data.data.length === 0;
        this.actionPlanSelectedId = this.slotData.id_action_plan;
        this.actionPlanHistory = !isEmpty ? data.data.data : {};
      } else {
        this.actionPlanSelectedId = this.slotData.id_action_plan;
      }
    } else {
      this.fetchActionPlans().then((resp) => {
        const { data: data } = resp.data;
        if (data.type === "action_plan_list") {
          this.actionPlans = data.data;
        } else {
          if (data.data) {
            const isEmpty = Array.isArray(data.data) && data.data.length === 0;
            this.actionPlanSelectedId = data.data.id || this.slotData.id_action_plan;
            this.actionPlanHistory = !isEmpty ? data.data : {};
          } else {
            this.actionPlanSelectedId = this.slotData.id_action_plan;
          }
        }
      });
    }

    this.config.uuid = this.slotData.uuid_action_plan_log
      ? this.slotData.uuid_action_plan_log
      : uuidv4();

    this.setActionPlanIdInSlotData({
      id_action_plan: this.actionPlanSelectedId,
      uuid_action_plan_log: this.config.uuid,
    });

    this.config.userId = this.user.id;
    this.config.alarmId = this.slotData.id || this.slotData.id_alarm || null;
  },
  computed: {
    user() {
      return store.getters["user/getUser"];
    },
  },
  methods: {
    fetchActionPlans() {
      return axios.get("/v1/action_plan/find-action-plan", {
        params: {
          id_alarm: this.slotData.id || null,
          alarm_type: this.slotData.type || null,
          id_tenant: this.slotData.tenant ? this.slotData.tenant.id : null,
          id_action_plan: this.slotData.id_action_plan || null,
          uuid: this.slotData.uuid_action_plan_log || null,
          check_for_logs: true,
        },
      });
    },
    onActionPlanSelected(actionPlan) {
      if (confirm(`¿Estás segur@ de inciar el plan de acción "${actionPlan.name}"?`)) {
        this.actionPlanSelectedId = actionPlan.id;
        this.setActionPlanIdInSlotData({ id_action_plan: this.actionPlanSelectedId });
      }
    },
    generateId() {
      if (this.slotData.id) {
        return null;
      } else if (this.slotData.uuid) {
        return this.slotData.uuid;
      }
      return uuidv4();
    },
    setActionPlanIdInSlotData(data) {
      layoutApi.updateSlotData(this.slotInfo.pos, SLOT_TYPES.ACTION_PLAN, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-slot-container {
  max-height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}
.action-plan-list {
  .action-plan-item {
    border: 1px solid rgb(112, 112, 112);
    padding: 3px;
    text-align: center;
    cursor: pointer;
    max-width: 175px;
  }
}
</style>

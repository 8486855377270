import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)
  const toast = useToast()
  const { t } = useI18nUtils();

  store.dispatch('tenant/getAll');
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems']);

  const tableColumns = [
    { label: t('action_plan.list.cols.col1'), key: 'action_plan.name', sortable: false },
    { label: t('action_plan.list.cols.col2'), key: 'user.email', sortable: false },
    { label: t('action_plan.list.cols.col3'), key: 'action_plan.tenant.name', sortable: false },
    { label: t('action_plan.list.cols.col4'), key: 'id_alarm', sortable: false },
    { label: t('action_plan.list.cols.col5'), key: 'created_at', sortable: false },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const selectedTenants = ref([])
  const startDatetimeFilter = ref(null)
  const endDatetimeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('action_plan/getLogs', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      id_tenant: selectedTenants.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      dt_from: startDatetimeFilter.value,
      dt_to: endDatetimeFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([
    currentPage,
    perPage,
    selectedTenants,
    startDatetimeFilter,
    endDatetimeFilter
  ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    tenantOptions,
    selectedTenants,
    startDatetimeFilter,
    endDatetimeFilter,
  }
}

<template>
  <div>
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <!-- Filters -->
    <filters
      :per-page.sync="perPage"
      :per-page-options="perPageOptions"
      :tenant-filter.sync="selectedTenants"
      :tenant-options="tenantOptions"
      :search-query.sync="searchQuery"
      :start-datetime.sync="startDatetimeFilter"
      :end-datetime.sync="endDatetimeFilter"
    />

    <!-- Table Container Card -->
      <b-card class="m-2" no-body>
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #cell(data)="data">
            <pre v-if="data.item.data">{{
              JSON.stringify(data.item.data, null, "\t")
            }}</pre>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card class="m-2" v-if="selectedObject" no-body>
        <feather-icon
          icon="XIcon"
          class="mr-2 ml-auto mt-2 float-rigth"
          style="cursor: pointer"
          size="30"
          @click="selectedObject = null"
        />
        <div class="mx-2">
          <action-plan-slot :data="selectedObject" />
        </div>
      </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, nextTick } from "@vue/composition-api";
import useList from "./useList";
import Pagination from "@/components/Pagination.vue";
import InfoSlot from "@/views/components/GridView/layouts/slots/InfoSlot";
import Filters from "./Filters.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SOURCES, SLOT_TYPES } from "@/config/layoutConfig";
import ActionPlanSlot from "@/views/components/GridView/layouts/slots/ActionPlanSlot.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Filters,
    Breadcrumb,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    Pagination,
    vSelect,
    InfoSlot,
    ActionPlanSlot,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    //Breadcrumb

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.logs.action_plan_logs");

    const { t } = useI18nUtils();
    const selectedObject = ref(null);

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      selectedTenants,
      startDatetimeFilter,
      endDatetimeFilter,
    } = useList();

    const onRowSelected = (item) => {
      selectedObject.value = null;
      nextTick(() => {
        const selectedItem = item[0];
        selectedObject.value = {
          data: {
            id_action_plan: selectedItem.id_action_plan,
            uuid_action_plan_log: selectedItem.uuid,
            type: SLOT_TYPES.ACTION_PLAN,
          },
        };
      });
    };

    return {
      // BreadCrumb
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,

      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      selectedTenants,
      startDatetimeFilter,
      endDatetimeFilter,
      onRowSelected,
      selectedObject,
    };
  },
};
</script>